import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { BalanceTableComponent } from '../balance-table/balance-table.component';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardComponent implements OnInit {

  @ViewChild(BalanceTableComponent) balance: BalanceTableComponent;

  constructor() {
  }

  ngOnInit() {
  }

}
