<div class="container-fluid px-0">
  <div class="row back ml-1 mr-0">

    <div class="col-12 col-lg-3 col-xl-2 mb-2 mt-1 p-0 mat-elevation-z2 order-1 full-market order-lg-0">
      <markets-sidebar #sidebar></markets-sidebar>
    </div>

    <div class="col-12 col-lg-9 col-xl-10 order-0 order-lg-1">
      <div class="row background-grey">
        <div class="col-12 fix-gutter mb-0">
          <div class="row mt-1 p-0">
            <div class="col-12 p-0" >
              <app-balance-cards></app-balance-cards>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 fix-gutter mb-2">
          <div class="row mat-elevation-z2 mt-1 balance-table p-0">
            <div class="col-12" >
              <app-balance-table></app-balance-table>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
