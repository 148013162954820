import { Component, OnInit, ViewChild, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SubscriptionLike } from 'rxjs';

import { BalancesService } from '../balances.service';
import { BalancesRequest, Coin } from '../../core/interfaces/balances';
import { CurrencyManagementComponent, DialogSetting } from '../currency-management/currency.management.component';
import { SessionStorageService } from '../../core/services/session-storage.service';

import { StoreService } from '../../core/services/store.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ProfileService } from 'src/app/profile/profile.service';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-balance-table',
  templateUrl: './balance-table.component.html',
  styleUrls: ['./balance-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BalanceTableComponent implements OnInit, OnDestroy {

  translatedTooltip: string[] = [
    this.i18n('Deposit'),
    this.i18n('Withdraw'),
    this.i18n('Trade'),
    this.i18n('Trade History'),
    this.i18n('Earn')
  ];

  BASE_URL: string = environment.config.BACKEND_URL;
  withdrawSettings: any;
  balance_data: MatTableDataSource<Coin> = new MatTableDataSource([]);
  showEarn: boolean = false;
  total_records: number;
  page_sizes: number[] = [5, 10, 20, 50];
  list_number: number = 10;
  page_loading: boolean;
  isLoading: boolean = true;
  withdrawalsDisabled: boolean = false;

  exchangeName: string = environment.config.EXCHANGE_NAME_L;

  data: BalancesRequest = {
    start: 0,
    length: this.list_number,
    search: '',
    fiat_evaluation: '',
    zeroBalance: false,
    orderBy: 'sort_order',
    order: '',
    orderType: 'ASC'
  };

  estimated_currencies: string[] = [
    'ZAR',
    'USDT'
  ];

  display_balances: string[] = [
    'All balances'
  ];

  estimated_selected: string = this.exchangeName === 'burnx' ? 'USDT' : this.estimated_currencies[0];

  display_selected: string = this.display_balances[0];

  showZeroBalances: boolean;

  search_filter: string;

  @ViewChild(MatSort, { static: true }) sort: MatSort;

  balance_definition: any = [
    'code',
    'name',
    'balance_available',
    'balance_pending_deposit',
    'balance_pending_withdraw',
    'balance_held',
    'earn',
    'balance_total',
    'btc_estimated_value',
    'estimated_value',
    'change',
    'action',
  ];

  private subs: SubscriptionLike[] = [];

  constructor(
    private balancesService: BalancesService,
    public dialog: MatDialog,
    private sessionStorage: SessionStorageService,
    private store: StoreService,
    private profileService: ProfileService,
    private i18n: I18n,
    private cdRef: ChangeDetectorRef
  ) { }

  reloadHandler: any;

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit() {
    this.withdrawSettings = this.sessionStorage.get('WITHDRAW_SETTINGS');
    if (this.withdrawSettings['enabled']) {
      if ((this.withdrawSettings.enabled.time) &&
        (+(this.withdrawSettings.enabled.time) + 86400 > Date.now() / 1000)) {
        this.withdrawalsDisabled = true;
      } else if (typeof this.withdrawSettings.enabled.value ===  'boolean') {
        if (!this.withdrawSettings.enabled.value) {
          this.withdrawalsDisabled = true;
        }
      }
    }

    this.profileService.getVerificationLevel().subscribe(result => {
      if (result.response === 'success') {
        this.sessionStorage.store('PROFILE_VERIFICATION_LEVEL', result.verificationLevel);
        this.sessionStorage.store('PROFILE_COUNTRY', result.country);
      } else {
        // Not logged in, add error handling here
      }
    });

    this.showZeroBalances = false;

    this.balance_data.paginator = this.paginator;
    this.balance_data.sort = this.sort;

    this.balance_data.filterPredicate = (data: any, filters: string) => {
      const filterJson = JSON.parse(filters);
      let withdraw = 0;
      let total = 0;
      let deposits = 0;
      try {
        withdraw = Number(data.balance_pending_withdraw);
        total = Number(data.balance_total);
        deposits = Number(data.balance_pending_deposit);
      } catch (ex) {
      }
      if (withdraw === 0 && total === 0 && deposits === 0 && filterJson.hideZero) {
        return false;
      } else {
        return data.code.toLowerCase().indexOf(filterJson.search && filterJson.search.toLowerCase() || '') > -1 ||
          data.name.toLowerCase().indexOf(filterJson.search && filterJson.search.toLowerCase() || '') > -1;
      }
    };
    this.reload_table();
    /*this.reloadHandler = setInterval(() => {
      this.reload_table();
    }, 10000);*/
    this.subs.push(this.store.subscribe('balances').subscribe((response: any) => {
      if (!response.refresh) {
        const temp: any[] = [];
        for (let i = 0; i < response.data.length; i++) {
          if ( this.exchangeName === 'burnx' ) {
            if (!!response.data[i].active) {
              if (!response.data[i].sort_order) {
                response.data[i].sort_order = 999;
              }
              response.data[i].earn = response.data[i].balance_earn;
              response.data[i].has_product = (typeof response.data[i].has_product === 'boolean'
                && response.data[i].has_product || response.data[i].has_product === 'true');
              temp.push(response.data[i]);
            }
          } else {
            if (!!response.data[i].active && response.data[i].active === '1') {
              // Check which icons to disable from withdrawal setings
              if (this.withdrawSettings[response.data[i].code]) {
                if (this.withdrawSettings[response.data[i].code].time &&
                    this.withdrawSettings[response.data[i].code].time + 86400 > Date.now() / 1000) {
                      response.data[i].disabled = true;
                }
                if (typeof this.withdrawSettings[response.data[i].code].value === 'boolean') {
                  if (!this.withdrawSettings[response.data[i].code].value) {
                    response.data[i].disabled = true; // avoid setting back to false
                  }
                }
              }

              if (!response.data[i].sort_order) {
                response.data[i].sort_order = 999;
              }
              response.data[i].earn = response.data[i].balance_earn;
              response.data[i].has_product = (typeof response.data[i].has_product === 'boolean'
              && response.data[i].has_product || response.data[i].has_product === 'true');
              this.showEarn = this.showEarn ? this.showEarn : response.data[i].has_product;
              temp.push(response.data[i]);
            }
          }
        }

        this.isLoading = false;
        this.balance_data.data = temp;
        this.reload_table(false);
        this.cdRef.detectChanges();
      }
    }));

    this.subs.push(this.store.subscribe('settings').subscribe((response: any) => {
      if (!response.refresh) {
        if (!!response.data.balanceSettings) {
          this.sessionStorage.store('PROFILE_BALANCE_SETTINGS', response.data.balanceSettings);
            this.showZeroBalances = !response.data.balanceSettings.display_zero ? false :
              response.data.balanceSettings.display_zero;
          if (!!response.data.balanceSettings.fiat_evaluation && this.exchangeName !== 'burnx') {
            this.estimated_selected = !response.data.balanceSettings.fiat_evaluation ? false :
              response.data.balanceSettings.fiat_evaluation;
          }
          if (!!response.data.balanceSettings.per_page) {
            this.data.length = !response.data.balanceSettings.per_page ? 10 :
              response.data.balanceSettings.per_page;
              this.list_number = this.data.length;

            setTimeout(() => {
              this.reload_table(false);
            }, 1000);
          }
        }
        if ((response.data.show_domain_prompt === 0) || (response.data.domains_available === false)) {
          this.sessionStorage.store('SHOW_DOMAIN_PROMPT', 0);
        }
        this.reload_table(false);
        this.cdRef.detectChanges();
      }
    }));
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    clearInterval(this.reloadHandler);

    this.subs.forEach(sub => !!sub && sub.unsubscribe());
  }

  get_columns() {
    return (this.showEarn ?
      this.balance_definition : this.balance_definition.filter(m => m !== 'earn'));
  }

  has_product(element: Coin) {
    return (element.code !== 'ZAR' && element.has_product);
  }

  reload_table(resetPagination: boolean = true) {
    const filters = {
      hideZero: this.showZeroBalances,
      search: !this.search_filter ? '' : this.search_filter
    };
    this.balance_data.filter = JSON.stringify(filters);

    if (resetPagination) {
      this.paginator.firstPage();
    }
  }

  paging_change($event: any) {
    const data: any = {
      setting: 'per_page',
      value: $event.pageSize
    };
    this.updateBalanceSetting(data);
    this.data.start = $event.pageIndex * $event.pageSize;
    this.data.length = $event.pageSize;

    this.reload_table(false);
  }

  clearFilter_datasource() {
    this.search_filter = '';
    this.filter_datasource();
  }

  filter_datasource() {
    if (!this.search_filter) {
      this.search_filter = '';
    }
    this.balance_data.filter = JSON.stringify({
      search_filter: this.search_filter
    });
    this.paginator.firstPage();
    this.data.start = 0;
    this.reload_table();
  }

  toggle_zero() {
    const data: any = {
      setting: 'display_zero',
      value: this.showZeroBalances
    };
    this.updateBalanceSetting(data);

    this.reload_table();
  }

  estimated_changed() {
    const data: any = {
      setting: 'fiat_evaluation',
      value: this.estimated_selected
    };
    this.updateBalanceSetting(data);

    this.reload_table();
  }

  updateBalanceSetting(data: any) {
    this.balancesService.updateBalanceSetting(data).subscribe(response => {
      if (response.response === 'success') {
        const balance_settings = {
          display_zero: this.showZeroBalances,
          fiat_evaluation: this.estimated_selected,
          per_page: this.data.length
        };
        this.sessionStorage.store('PROFILE_BALANCE_SETTINGS', balance_settings);
        this.store.saveBalanceSettings(balance_settings);
      }
      this.reload_table(false);
    });
  }

  deposit_click(element: Coin) {
    const data: DialogSetting = {
      code: element.code,
      type: element.coin_type,
      icon: element.icon,
      mode: 0,
      has_product: element.has_product,
      has_coin_networks: element.has_coin_networks
    };
    this.dialog.open(CurrencyManagementComponent, {
      width: '1200px',
      data: data
    });
  }

  withdraw_click(element: Coin) {
    const data: DialogSetting = {
      code: element.code,
      type: element.coin_type,
      icon: element.icon,
      mode: 1,
      has_product: element.has_product,
      has_coin_networks: element.has_coin_networks
    };
    this.dialog.open(CurrencyManagementComponent, {
      width: '1200px',
      data: data
    });
  }

  history_click(element: Coin) {
    const data: DialogSetting = {
      code: element.code,
      type: element.coin_type,
      icon: element.icon,
      mode: 3,
      has_product: element.has_product,
      has_coin_networks: element.has_coin_networks
    };
    this.dialog.open(CurrencyManagementComponent, {
      width: '1200px',
      data: data
    });
  }

  earn_click(element: Coin) {
    const data: DialogSetting = {
      code: element.code,
      type: element.coin_type,
      icon: element.icon,
      mode: 4,
      has_product: element.has_product,
      has_coin_networks: element.has_coin_networks
    };
    this.dialog.open(CurrencyManagementComponent, {
      width: '1200px',
      data: data
    });
  }

  changes_click(element: Coin) {
    const data: DialogSetting = {
      code: element.code,
      type: element.coin_type,
      icon: element.icon,
      mode: 2,
      has_product: element.has_product,
      has_coin_networks: element.has_coin_networks
    };
    this.dialog.open(CurrencyManagementComponent, {
      width: '',
      data: data
    });
  }

  buildImageString(coinName: string) {
    const imageurl = this.BASE_URL + '/action/getCoin?coinIcon=' + coinName;
    return imageurl;

  }

  trackByCoin(_index: number, coin: any) {
    return coin && coin.code;
  }
}
