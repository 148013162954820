<div class="row">
  <div class="col-10 col-xlg-11 px-0 note-box clickable">
    <div class="row">
      <div class="col pr-0">
        <div class="row">
            <div class="col-12 summary-total">
              <div class="row justify-content-md-end align-items-center cb-main-position summary-total-heading">
                <mat-icon svgIcon="estimatedvalue" i18n-matTooltip matTooltip="Trading Summary" placement="bottom" color="primary" class="lg-icon mr-1" [matMenuTriggerFor]="menu"></mat-icon>
                &nbsp;
                <span [matMenuTriggerFor]="menu" class="min-w-limit" placement="bottom" i18n-matTooltip [matTooltip]="translatedWords['Estimated Value: ']+totalBTC+'/'+totalFiat">{{totalBTC}}</span>
                <mat-icon class="center-btn-icon ml-1" placement="bottom" i18n-matTooltip matTooltip="Trading Summary" [matMenuTriggerFor]="menu">arrow_drop_down_circle</mat-icon>
              </div>
              <div class="row sm-position align-items-start justify-content-md-end est-label pr-30px">
                  <label [matMenuTriggerFor]="menu" i18n>EST Value: {{totalFiat}}</label>
              </div>
            </div>
        </div>

      </div>
      <div>
          <mat-menu class="trading-summary mt-3" #menu="matMenu" [overlapTrigger]="false" >
            <div class="pt-2 pb-2 pl-3 pr-3" (click) = "$event.stopPropagation()">
              <div class="mb-2 font-weight-bold" i18n>YOUR 30 DAY TRADING SUMMARY</div>
              <div class="row">
                <div class="col-7 font-weight-bold" i18n>Your 30 Day Trading volume:</div>
                <div class="col-5">{{tradeSummary30dVolume}} BTC</div>
              </div>
              <div class="mb-2 row">
                <div class="col-7 font-weight-bold" i18n>Your current trading fees:</div>
                <div class="col-5">
                  <span i18n>Maker: </span>{{tradeSummaryMakerTradeFee}}%<br />
                  <span i18n>Taker: </span>{{tradeSummaryTakerTradeFee}}%
                </div>
              </div>
              <div>
                <span i18n>
                  Reduce your trading fees by increasing your 30 day trading volume. To learn more about our scaling fees and
                  how to take advantage of them, </span>
                  <a href="{{getSupportUrl()}}/support/solutions/articles/35000101034-what-are-scaling-fees-and-how-does-it-work" target="_blank" i18n>click here</a>
              </div>
            </div>
          </mat-menu>
      </div>
    </div>
  </div>
</div>
