import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule, MatIcon } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';

import { routing } from './balances.routing';
import { AuthGuard, NoAuthGuard } from './../core/services/auth-guard.service';

import { BalanceTableComponent } from './balance-table/balance-table.component';
import { CoinBalanceComponent } from './balance-table/coin-balance/coin-balance.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LinkAccountDialogComponent } from './link-account-dialog/link-account-dialog.component';
import { AddressBookComponent } from './address-book/crypto-address-book/address-book.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routing,
    MatTableModule,
    MatDialogModule,
    MatInputModule,
    MatSelectModule,
    MatIconModule,
    MatTabsModule,
    MatButtonModule,
    MatCheckboxModule,
    MatPaginatorModule,
    MatSortModule,
    MatMenuModule,
    SharedModule,
  ],
  declarations: [
    BalanceTableComponent,
    CoinBalanceComponent,
    DashboardComponent,
    AddressBookComponent,

  ],
  providers: [
    AuthGuard,
    NoAuthGuard
  ],
  entryComponents: [
    LinkAccountDialogComponent,
    MatIcon
  ],
  exports: [
    ReactiveFormsModule
  ]
})
export class BalancesModule { }
