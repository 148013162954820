import { environment } from './../../../../environments/environment';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { StoreService } from '../../../core/services/store.service';
import { BalancesService } from '../../balances.service';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
  selector: 'app-coin-balance',
  templateUrl: './coin-balance.component.html',
  styleUrls: ['./coin-balance.component.scss']
})
export class CoinBalanceComponent implements OnInit, OnDestroy {

  totalBTC: string;
  totalFiat: string;

  subs: any = [];
  tradeSummary30dVolume: string = '0';
  tradeSummaryMakerTradeFee: string = '0.000';
  tradeSummaryTakerTradeFee: string = '0.000';

  translatedWords: Object = {
    'Estimated Value: ': this.i18n('Estimated Value: ')
  };

  exchangeName: string = environment.config.EXCHANGE_NAME_L;

  constructor(
    private balancesService: BalancesService,
    private store: StoreService,
    private i18n: I18n
  ) { }

  ngOnInit() {
    this.loadTradeSummary();
    this.loadCoinBalance();
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  getSupportUrl(): string {
    return environment.config.SUPPORT_URL;
  }

  loadCoinBalance() {
    this.subs.push(this.store.subscribe('totalSummary').subscribe(response => {
      if (!response.refresh) {
        this.totalBTC = response.data.totalBitcoin + ' BTC';
        if (this.exchangeName === 'burnx') {
          this.totalFiat = '$ ' + response.data.totalUSDT;
        } else {
          this.totalFiat = response.data.zarFiatType ? 'R ' + response.data.totalZAR : '$ ' + response.data.totalUSDT;
        }
      }
    }));
  }

  loadTradeSummary() {
    this.balancesService.getUserFee()
      .subscribe((response: any) => {
        if (response.response === 'success' && response.data) {
          this.tradeSummary30dVolume = !!response.data.total_volume ? response.data.total_volume : 0;
          this.tradeSummaryMakerTradeFee = Number(response.data.maker_fee).toFixed(3);
          this.tradeSummaryTakerTradeFee = Number(response.data.taker_fee).toFixed(3);
        }
      });
  }
}
