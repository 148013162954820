import { ModuleWithProviders } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from '../core/services/auth-guard.service';
import { DashboardComponent } from './dashboard/dashboard.component';
import {
  ConfirmWithdrawalComponent
} from './currency-management/withdrawal/confirm-withdrawal/confirm-withdrawal.component';
import { MetaGuard } from '@ngx-meta/core';

import { environment } from '../../environments/environment';
import { BalancesModule } from './balances.module';
import { RequestConfirmComponent } from '../request-confirm/request-confirm.component';

const routes: Routes = [
  {
    path: 'balances', component: DashboardComponent, data: {
    title: 'Balances',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - ' + 'Balances',
      description: 'All your Digital Currency Balances in one place!'
    } }, canActivate: [AuthGuard, MetaGuard]
  },
  {
    path: 'withdraw/confirm/:hash', component: ConfirmWithdrawalComponent, data: {
    title: 'Withdrawal Confirmation',
    meta: {
      title: environment.config.EXCHANGE_NAME + ' - ' + 'Withdrawal Confirmation',
      description: environment.config.EXCHANGE_NAME
    } }, canActivate: [AuthGuard, MetaGuard]
  },
  {
    path: 'withdraw/cancel/:Hash', component: ConfirmWithdrawalComponent, data: {
      title: 'Cancel Withdrawal',
      meta: {
        title: environment.config.EXCHANGE_NAME + ' - ' + 'Cancel Withdrawal',
        description: environment.config.EXCHANGE_NAME
      }
    }, canActivate: [MetaGuard]
  }
];

export const routing: ModuleWithProviders<BalancesModule> = RouterModule.forChild(routes);
